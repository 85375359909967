.navbar-custom{
    background-color: #000000	!important;
}

.font-navbar-custom{
    font-family: 'Ma Shan Zheng', cursive;
}



@font-face {
    font-family: "CustomFont";
    src: url("../lib/Font/aga-rasheeq-bold-webfont.woff") format("woff"),
    }

.font-arab{
    font-family: 'Katibeh', cursive;
}

.footer {
    background: #101111;
    height: auto;
    padding-bottom: 30px;
    position: relative;
    width: 100%;
    border-bottom: 1px solid #CCCCCC;
    border-top: 1px solid #DDDDDD;
    }
    .footer p {
    margin: 0;
    }
    .footer img {
    max-width: 100%;
    }
    .footer h3 {
    color: white;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    padding: 40px 0 0px;
    text-transform: uppercase;
    margin-bottom: 15px;
    }
    
    .footer h4 {
    color: white;
    font-size: 2em;
    font-weight: 600;
    line-height: 38px;
    padding: 40px 0 10px;
    font-family: cursive;
    font-weight: lighter
    }
    
    .footer ul {
    font-size: 13px;
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    margin-top: 0px;
    color: #7F8C8D;
    padding: 0 0 8px 0;
    }
    
    .email{
    border-bottom: 3px solid #fff;
    }
    .footer ul li a {
    padding: 0 0 12px 0;
    display: block;
    }
    .footer a {
    color: white;
    font-weight: lighter;
    }
    
    .footer p {
    color: white;
    font-weight: lighter;
    
    }
    
    .footer a:hover {
    text-decoration:none;
    font-weight: bold;
    }
    .supportLi h4 {
    font-size: 20px;
    font-weight: lighter;
    line-height: normal;
    margin-bottom: 0 !important;
    padding-bottom: 0;
    }
    
    .bg-gray {
    background-image: -moz-linear-gradient(center bottom, #BBBBBB 0%, #F0F0F0 100%);
    box-shadow: 0 1px 0 #B4B3B3;
    }
    
    .footer a {
    color: #78828D
    }
    
    .footer-bottom {
    margin-top: 2em;
    border-top: 1px solid #DDDDDD;
    border-bottom: transparent;
    padding-top: 20px;
    padding-bottom: 10px;
    }
    .footer-bottom p.pull-left {
    padding-top: 6px;
    font-size: 0.75em
    }